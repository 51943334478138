import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { ReactComponent as IconFAQ } from "../../../assets/images/contact-page/FAQ_ContactUs.svg";

const ContactForm = () => {
  const [form, setForms] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: "",
      subject: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Must be at least 3 characters long")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
        .required("This field is required"),
      subject: Yup.string()
        .min(5, "Must be at least 5 characters long")
        .required("This field is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("This field is required"),
      message: Yup.string()
        .min(20, "Must be at least 20 characters long")
        .required("This field is required"),
    }),
    onSubmit: (values) => {
      axios
        .post(
          "https://api.favesolution.com/api/form",
          {
            form_name: values.name,
            form_subject: values.subject,
            form_email: values.email,
            form_message: values.message,
          },
          { withCredentials: true }
        )
        .then((result) => {
          if (result.status === 200) {
            alert("Your form has been sent!");
            console.log(result.data);
            setForms(result.data);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="cc-desc">
        <IconFAQ className="ccIcon" />
        <div className="ccdesc-content">
          <div className="ccdc-top">Have some Questions?</div>
          <div className="ccdc-bot">
            Feel free to fill the given form here and let us know about your
            idea or what concerns you.
          </div>
        </div>
      </div>

      <div className="cc-form">
        <div className="cc-formText">
          <label>Name</label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Your Name"
            onChange={formik.handleChange}
            defaultValue={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div>{formik.errors.name}</div>
          ) : null}
        </div>
        <div className="cc-formText cc-interval">
          <label>Subject</label>
          <input
            id="subject"
            name="subject"
            type="text"
            placeholder="Your Subject"
            onChange={formik.handleChange}
            defaultValue={formik.values.subject}
          />
          {formik.touched.subject && formik.errors.subject ? (
            <div>{formik.errors.subject}</div>
          ) : null}
        </div>
        <div className="cc-formText">
          <label>Email</label>
          <input
            id="email"
            name="email"
            type="email"
            placeholder="Your Email"
            onChange={formik.handleChange}
            defaultValue={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div>{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="cc-formText cc-interval">
          <label>Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Your Message"
            onChange={formik.handleChange}
            defaultValue={formik.values.message}
          />
          {formik.touched.message && formik.errors.message ? (
            <div>{formik.errors.message}</div>
          ) : null}
        </div>
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default ContactForm;
