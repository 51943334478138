import React from 'react';

import Button from '../Button';

const Card = (props) => {
    return (
      <div className={props.status + " ourworks-bp-main"}>
        <div className="ourworks-bpm-left">
          <div className="ourworks-bpml-title">{props.name}</div>
          <div className="ourworks-bpml-content">{props.details}</div>
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            <Button text="Discover Our Work" />
          </a>
        </div>
        <img
          className="ourworks-bpm-imghp"
          src={props.picture}
          alt="FAVE Solution"
        />
      </div>
    );
}

export default Card;