import React from "react";

import StudyCase from "../components/pages/studycase-page/StudyCase";
import LetsTalk from "../components/pages/home-page/lets-talk/LetsTalk";
import Footer from "../components/common/Footer";

const StudycasePage = (props) => {
  return (
    <section className="workspage">
      <StudyCase baseUrl={props.baseUrl} />
      <LetsTalk />
      <Footer />
    </section>
  );
};

export default StudycasePage;
