import React, { useState } from "react";

import { ReactComponent as ButtonTriangle } from "../../../../assets/images/arrowTriangle.svg";
import Card from "./CardOurWorks";
import CarouselBtn from "../CarouselBtn";

const Ourworks = (props) => {
  // console.log(JSON.stringify(props))
  // console.log(props)
  const [active, setActive] = useState(1);
  const maxSize = Object.keys(props.works).length;

  // const dummyData = [{
  //     name: "alpha",
  //     details: "lorem loremlorem loremlorem loremlorem loremlorem loremlorem loremlorem lorem",
  //     imghp: "sample"
  // },{
  //     name: "beta",
  //     details: "lorem loremlorem loremlorem loremlorem loremlorem loremlorem loremlorem lorem",
  //     imghp: "sgfnth"
  // },{
  //     name: "charlie",
  //     details: "lorem loremlorem loremlorem loremlorem loremlorem loremlorem loremlorem lorem",
  //     imghp: "testtttt"
  // },{
  //     name: "delta",
  //     details: "lorem loremlorem loremlorem loremlorem loremlorem loremlorem loremlorem lorem",
  //     imghp: "aswfawafa"
  // },{
  //     name: "echo",
  //     details: "lorem loremlorem loremlorem loremlorem loremlorem loremlorem loremlorem lorem",
  //     imghp: "sdfnsfhns"
  // },{
  //     name: "fanta",
  //     details: "lorem loremlorem loremlorem loremlorem loremlorem loremlorem loremlorem lorem",
  //     imghp: "asdfa"
  // },{
  //     name: "gorgo",
  //     details: "lorem loremlorem loremlorem loremlorem loremlorem loremlorem loremlorem lorem",
  //     imghp: "qwerqwer"
  // }]

  const arrowLeft = () => {
    if (active === 1) {
      setActive(maxSize);
    } else {
      setActive(active - 1);
    }
  };

  const arrowRight = () => {
    if (active === maxSize) {
      setActive(1);
    } else {
      setActive(active + 1);
    }
  };

  const goTo = (x) => {
    setActive(x);
  };

  return (
    <section id="Ourworks" className="ourexcellentworks">
      <div className="ourworks header">
        <span className="ourworks-h-our">
          Our <span className="ourworks-h-ew">Excellent Works</span>
        </span>
      </div>
      <div className="ourworks-body">
        <div className="ourworks-b-project">
          <div className="ourworks-bp-btn-space">
            <div className="ourworks-bp-btn owbpbtnr" onClick={arrowLeft}>
              <ButtonTriangle className="ow-btn-left" />
            </div>
          </div>

          {/* Dummy Data */}
          {/* <div className="ourworks-bp-cardholder">
                        {dummyData.map((el,i)=>{
                            return(
                                <div
                                key={el.id}
                                className={(active === i+1 ? 'ourworks-bp-cardabsolute active-animation' : 'ourworks-bp-cardabsolute')}>
                                    <Card 
                                        name={el.name}
                                        details={el.details}
                                        imghp={el.imghp} />
                                </div>
                            )
                        })}
                    </div> */}

          {/* Checkpoint props */}
          <div className="ourworks-bp-cardholder">
            {props.works.map((data, i) => {
              return (
                <div
                  key={data.id}
                  className={
                    active === i + 1
                      ? "ourworks-bp-cardabsolute active-animation"
                      : "ourworks-bp-cardabsolute"
                  }
                >
                  <Card
                    name={data.work_name}
                    details={data.work_details}
                    picture={props.baseUrl + data.work_picture}
                    link={data.work_process}
                  />
                </div>
              );
            })}
          </div>

          <div className="ourworks-bp-btn-space">
            <div className="ourworks-bp-btn owbpbtnl" onClick={arrowRight}>
              <ButtonTriangle className="ow-btn-right" />
            </div>
          </div>
        </div>

        <div className="carousel-btn-wrapper upseperate">
          <div className="carousel-btn-btns dark">
            {props.works.map((data, i) => (
              <span
                className={active === i + 1 ? "activebtn" : ""}
                onClick={() => goTo(i + 1)}
              >
                <CarouselBtn />
              </span>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ourworks;
