import React from "react";

import CardOurTeam from "./CardOurTeam";

import PRFAVE from "../../../../assets/images/about-page/fave-pr.png";
import FAVEManager from "../../../../assets/images/about-page/fave-manager.png";
import FAVEStaff1 from "../../../../assets/images/about-page/fave-staff1.png";
import FAVEStaff2 from "../../../../assets/images/about-page/fave-staff2.png";
import FAVEStaff3 from "../../../../assets/images/about-page/fave-staff3.png";

const OurTeam = () => {
  return (
    <section className="our-team">
      <div className="container">
        <div className="header-container">
          <div className="vl"></div>
          <div className="header">
            Our <span>Team</span>
          </div>
        </div>
        <p>
          We’re led by a team of young, passionate programmers, and designers
          who constantly question, tinker, and challenge our creativity
          boundaries, one project at a time.
        </p>
        <div className="team-cards-wrapper">
          <CardOurTeam
            imageSrc={PRFAVE}
            imageAlt="prfave-img"
            name="Raymond Akkasel Jaya Imanuel"
            position="PR FAVE"
          />
          <CardOurTeam
            imageSrc={FAVEManager}
            imageAlt="favemanager-img"
            name="Gamaliel Satria Digdojo"
            position="FAVE Manager"
          />
          <CardOurTeam
            imageSrc={FAVEStaff1}
            imageAlt="favestaff1-img"
            name="Edwin Tjandraatmadja"
            position="FAVE Staff"
          />
          <CardOurTeam
            imageSrc={FAVEStaff2}
            imageAlt="favestaff2-img"
            name="Rheza Ahmad Ramadan"
            position="FAVE Staff"
          />
          <CardOurTeam
            imageSrc={FAVEStaff3}
            imageAlt="favestaff3-img"
            name="Ignatius De Loyola Jurian Ratria Galih"
            position="FAVE Staff"
          />
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
