import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import axios from "axios";

const StudyCase = (props) => {
  // const [isLoading, setLoading] = useState(true);
  const [project, setProject] = useState([]);
  const routeParam = useParams();

  useEffect(async () => {
    axios
      .get(props.baseUrl + "/api/works", { withCredentials: true })
      .then(({ data }) => {
        setProject(data.find((obj) => obj.work_name === routeParam.route));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <section className="study-case">
      <div className="container">
        <a href="/our-works" className="back-btn">
          Back to Our Works
        </a>
        <div className="header">{project.work_name}</div>
        <div className="project-thumbnail-wrapper">
          <img
            src={props.baseUrl + "/" + project.work_picture}
            alt="FAVE Solution"
          />
        </div>
        <div className="study-case-content">
          <div className="sub-title">Project Details</div>
          <div className="sub-text">{project.work_details}</div>
        </div>
        <div className="study-case-content">
          <div className="sub-title">Our Responsibilities</div>
          <div className="sub-text">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Consectetur, vitae molestias? Repudiandae repellendus, rem,
            recusandae molestias libero voluptatum laboriosam unde, porro
            temporibus odio nihil suscipit eum totam rerum explicabo saepe.
          </div>
        </div>
        <div className="study-case-content">
          <div className="sub-title">Tech Stack</div>
          <div className="flex-box tech-stack-list">
            <div className="tech-stack">
              <img
                src={props.baseUrl + "/" + project.work_picture}
                alt="FAVE Solution"
              />
              <p className="tech-stack-name">HTML</p>
            </div>
            <div className="tech-stack">
              <img
                src={props.baseUrl + "/" + project.work_picture}
                alt="FAVE Solution"
              />
              <p className="tech-stack-name">CSS</p>
            </div>
            <div className="tech-stack">
              <img
                src={props.baseUrl + "/" + project.work_picture}
                alt="FAVE Solution"
              />
              <p className="tech-stack-name">Laravel</p>
            </div>
            <div className="tech-stack">
              <img
                src={props.baseUrl + "/" + project.work_picture}
                alt="FAVE Solution"
              />
              <p className="tech-stack-name">JS</p>
            </div>
          </div>
        </div>
        <div className="study-case-content">
          <div className="sub-title">Gallery</div>
          <div className="flex-box gallery-list">
            <img
              src={props.baseUrl + "/" + project.work_picture}
              alt="FAVE Solution"
            />
            <img
              src={props.baseUrl + "/" + project.work_picture}
              alt="FAVE Solution"
            />
            <img
              src={props.baseUrl + "/" + project.work_picture}
              alt="FAVE Solution"
            />
            <img
              src={props.baseUrl + "/" + project.work_picture}
              alt="FAVE Solution"
            />
            <img
              src={props.baseUrl + "/" + project.work_picture}
              alt="FAVE Solution"
            />
            <img
              src={props.baseUrl + "/" + project.work_picture}
              alt="FAVE Solution"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default StudyCase;
