import React from 'react';

const ArrowIcon = () =>{
    return(
        <>
           <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30" fill="none">
                <path d="M6.16675 15H22.5001" stroke="#F9C92D" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.3334 6.62927L22.5 15.0001L14.3334 23.3709" stroke="#F9C92D" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </>
    )
}

export default ArrowIcon;