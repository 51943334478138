import React from 'react';
import { Link } from 'react-router-dom';

import Button2 from '../Button2';

const LetsTalk = () => {
    return(
        <section className="letstalk">
            <div className="container">
                <div className="left-side">
                    <div className="header-container">
                        <div className="vl"/>
                        <div className="header">
                            Got a <span>Project</span> in mind?
                        </div>
                    </div>
                    <p>
                        Let us know by clicking the button here or contacting us through our social media, get to know more about us!
                    </p>
                </div>
                <div className="right-side">
                    <Link to="/contact-us">
                        <Button2 text="Let's Talk" />
                    </Link>  
                </div>
            </div>
        </section>
    )
}

export default LetsTalk;