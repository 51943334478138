import React from 'react';

const CapabilitiesCard = props =>{
    return (
        <div className="card">
            <img src={props.imageUrl} alt={props.imageAlt}/>
            <div className="card-content">
                <h3 className="card-title">{props.title}</h3>
                <p>{props.content}</p>
            </div>
        </div>
    )
}

export default CapabilitiesCard;