import React from "react";

import fave from "../../assets/images/common/fave-logo/logo-color.png";
import bncc from "../../assets/images/common/bncc-logo/bncc.png";
import dribbble from "../../assets/images/common/social-media/dribbble.png";
import facebook from "../../assets/images/common/social-media/facebook.png";

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="top">
          <div className="left-side">
            <img src={fave} alt="fave" />
            <div className="contact-info">
              <a href="mailto:favesolution@bncc.net">
                <p>favesolution@bncc.net</p>
              </a>
              <a
                href="https://wa.me/6282129502403"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>+62-821-2950-2403 (Raymond Akkasel)</p>
              </a>
            </div>
            <div className="social-media">
              <a
                href="https://www.facebook.com/favesolution/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </a>
              <a
                href="https://dribbble.com/favesolution"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dribbble} alt="dribbble" />
              </a>
            </div>
          </div>
          <div className="right-side">
            <p>Managed by:</p>
            <a
              href="https://bncc.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={bncc} alt="logo" />
            </a>
          </div>
        </div>
        <div className="bottom">
          <div className="text-background">
            <p>Copyright 2024 FAVE SOLUTION, All rights reserved.</p>
          </div>
          <hr />
        </div>
      </div>
    </section>
  );
};

export default Footer;
