import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import DarkFavicon from "./assets/images/common/favicon/favicon.svg";
import LightFavicon from "./assets/images/common/favicon/favicon-color.svg";

import "./styles/main.scss";
import HomePage from "./pages/homePage";
import AboutPage from "./pages/aboutPage";
import ContactPage from "./pages/contactPage";
import WorksPage from "./pages/worksPage";
import StudycasePage from "./pages/studycasePage";
import ScrollToTop from "./ScrollToTop";

const App = () => {
  const baseUrl = "https://api.favesolution.com";
  const [works, setWorks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [testimonies, setTestimonies] = useState([]);

  useEffect(() => {
    const fetchDataWorks = async () => {
      axios
        .get(baseUrl + "/api/works", { withCredentials: true })
        .then((result) => {
          setWorks(result.data);
        });
    };
    const fetchDataTestimonies = async () => {
      axios
        .get(baseUrl + "/api/testimonies", { withCredentials: true })
        .then((result) => {
          setTestimonies(result.data);
        });
    };
    fetchDataWorks();
    fetchDataTestimonies();
  }, []);

  return (
    <>
      <Helmet>
        <title>FAVE Solution</title>
        <link
          rel="icon"
          type="image/x-icon/svg+xml"
          href={LightFavicon}
          data-react-helmet="true"
          media="(prefers-color-scheme: light)"
          sizes="any"
        ></link>
        <link
          rel="icon"
          type="image/x-icon/svg+xml"
          href={DarkFavicon}
          data-react-helmet="true"
          media="(prefers-color-scheme: dark)"
          sizes="any"
        ></link>
        <meta
          name="description"
          content="FAVE Solution is a professional software house in Indonesia. We create Websites and Mobile Apps with our utmost care for our clients."
          data-react-helmet="true"
        />
        <link rel="canonical" href={baseUrl} />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/">
            <ScrollToTop>
              <HomePage
                path="home"
                testimonies={testimonies}
                works={works}
                baseUrl={baseUrl}
              />
            </ScrollToTop>
          </Route>
          <Route exact path="/about-us">
            <ScrollToTop>
              <AboutPage path="about us" />
            </ScrollToTop>
          </Route>
          <Route exact path="/contact-us">
            <ScrollToTop>
              <ContactPage path="contact us" />
            </ScrollToTop>
          </Route>
          <Route exact path="/our-works">
            <ScrollToTop>
              <WorksPage path="our works" projects={works} baseUrl={baseUrl} />
            </ScrollToTop>
          </Route>
          <Route
            path="/project/:route"
            render={({ match }) => (
              <ScrollToTop>
                <StudycasePage
                  project={projects.find(
                    (p) => p.route === "/project/" + match.params.route
                  )}
                  projects={projects}
                  baseUrl={baseUrl}
                />
              </ScrollToTop>
            )}
          />
        </Switch>
      </Router>
    </>
  );
};

export default App;
