import React from 'react';

import ArrowIcon from './ArrowIcon';

const Button = props =>{
    return (
        <div className="button-wrapper">
            <div className="button-btn">
                <p>{props.text}</p>
            </div>
            <ArrowIcon />
        </div>
    )
}

export default Button;