import React from "react";
import CapabilitiesCard from "./CapabilitiesCard";

import MobileIcon from "../../../../assets/images/home-page/our-capabilites/mobile-icon.png";
import UIUXIcon from "../../../../assets/images/home-page/our-capabilites/ui-ux-icon.png";
import WebDevIcon from "../../../../assets/images/home-page/our-capabilites/web-dev-icon.png";

const OurCapabilities = () => {
  return (
    <section className="our-capabilities">
      <div className="container">
        <div className="header">
          Our <span>Capabilities</span>
        </div>
        <div className="card-wrapper">
          <CapabilitiesCard
            imageUrl={MobileIcon}
            imageAlt="mobile-icon"
            title="Mobile App Development"
            content="A world where almost everything can be done with a single touch of your hand, we develop custom mobile applications suitable only for you and your business!"
          />
          <CapabilitiesCard
            imageUrl={UIUXIcon}
            imageAlt="ui/ux-icon"
            title="UI/UX Design"
            content="We explore the world of colors and shapes, mixing them with today’s trend to create a design which best describes who you are!"
          />
          <CapabilitiesCard
            imageUrl={WebDevIcon}
            imageAlt="webdev-icon"
            title="Web Development"
            content="Technology develops over time, going online, having wider reach, and so should you, we build websites with latest technology you wish and reach you online every time."
          />
        </div>
      </div>
    </section>
  );
};

export default OurCapabilities;
