import React from 'react';

import Navbar from '../components/common/Navbar';
import Hero from '../components/pages/home-page/Hero';
import AboutUs from '../components/pages/home-page/about-us/AboutUs';
import OurCapabilities from '../components/pages/home-page/our-capabilities/OurCapabilities';
import Ourworks from '../components/pages/home-page/our-work/Ourworks';
// import OurTestimony from '../components/pages/home-page/our-testimony/OurTestimony';
import LetsTalk from '../components/pages/home-page/lets-talk/LetsTalk';
import Footer from '../components/common/Footer'

const HomePage = (props) => {
    return(
        <>
            <Navbar path={props.path} />
            <Hero />
            <AboutUs />
            <OurCapabilities />
            <Ourworks
                works={props.works}
                baseUrl={props.baseUrl}
            />
            {/* <OurTestimony 
                testimonies={props.testimonies}
                baseUrl={props.baseUrl}
            /> */}
            <LetsTalk />
            <Footer />
        </>
    )
}

export default HomePage;