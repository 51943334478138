import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/common/fave-logo/logo.png";
import menuIcon from "../../assets/images/common/menu-icon.svg";
import closeIcon from "../../assets/images/common/close-icon.svg";

const Navbar = (props) => {
  let listener = null;
  const [scrollState, setScrollState] = useState("top");
  const [menuState, setMenuState] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120 && isSubscribed) {
        if (scrollState !== "amir") {
          setScrollState("amir");
        }
      } else {
        if (scrollState !== "top" && isSubscribed) {
          setScrollState("top");
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
      isSubscribed = false;
    };
  }, [scrollState]);

  const handleMenu = () => {
    setMenuState((prev) => !prev);
    console.log("handleMenu");
  };

  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-100px";
    }
    prevScrollpos = currentScrollPos;
  };

  return (
    <nav
      id="navbar"
      className={
        props.path === "home"
          ? scrollState === "top"
            ? ""
            : "scrolled"
          : "scrolled"
      }
    >
      <Link to="/">
        <img className="logo" src={logo} alt="FAVE Solution" />
      </Link>
      <img
        className="menu-icon"
        src={menuIcon}
        alt="FAVE Solution"
        onClick={() => handleMenu()}
      />
      <div className={(menuState ? "active-menu" : "inactive-menu") + " menu"}>
        <img
          className="close-icon"
          src={closeIcon}
          alt="FAVE Solution"
          onClick={() => handleMenu()}
        />
        <Link
          className={(props.path === "home" ? "active" : "") + " menu-list"}
          to="/"
        >
          Home
        </Link>
        <div className="menu-line"></div>
        <Link
          className={(props.path === "about us" ? "active" : "") + " menu-list"}
          to="/about-us"
        >
          About Us
        </Link>
        <div className="menu-line"></div>
        <Link
          className={
            (props.path === "our works" ? "active" : "") + " menu-list"
          }
          to="/our-works"
        >
          Our Works
        </Link>
        <div className="menu-line"></div>
        <Link
          className={
            (props.path === "contact us" ? "active" : "") + " menu-list"
          }
          to="/contact-us"
        >
          Contact Us
        </Link>
        <div className="menu-line"></div>
      </div>
    </nav>
  );
};

export default Navbar;
