import React from 'react';

import Navbar from '../components/common/Navbar';
import AboutSection from '../components/pages/about-page/about-section/AboutSection';
import OurTeam from '../components/pages/about-page/our-team/OurTeam';
import LetsTalk from '../components/pages/home-page/lets-talk/LetsTalk';
import Footer from '../components/common/Footer';

const AboutPage = (props) => {
    return(
        <>
            <Navbar path={props.path}/>
            <AboutSection/>
            <OurTeam/>
            <LetsTalk />
            <Footer/>
        </>
    )
}

export default AboutPage