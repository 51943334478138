import React from "react";

import { ReactComponent as TriangleBG } from "../../../assets/images/common/TrianglePolygonBG.svg";
import ContactForm from "./Form";

const ContactUs = () => {
  return (
    <section className="contact-us">
      <TriangleBG className="toptriangle" />
      <div className="c-header">
        <div className="ch-line"></div>
        <h1 className="ch-title">
          Contact <span>Us</span>
        </h1>
      </div>

      <div className="c-content">
        <ContactForm />
      </div>
      <TriangleBG className="bottriangle" />
    </section>
  );
};

export default ContactUs;
