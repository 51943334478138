import React from 'react';

const CardAboutSection = (props) => {
    return(
        <div className={"card-wrapper " + props.status}>
            <div className="header">{props.title}<span>{props.yellow}</span>{props.title2}</div>
            <p>{props.content}</p>
        </div>
    )
}

export default CardAboutSection