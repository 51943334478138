import React from 'react';

import Navbar from '../components/common/Navbar';
import ContactUs from '../components/pages/contact-page/Contact';
// import LetsTalk from '../components/pages/home-page/lets-talk/LetsTalk';
import Footer from '../components/common/Footer'

const ContactPage = (props) => {
    return(
        <section className="contactpage">
            <Navbar path={props.path} />
            <ContactUs />
            <Footer />
        </section>
    )
}

export default ContactPage