import React from "react";
import Button from "../home-page/Button";

const WorksList = (props) => {
  console.log(props);
  return (
    <div className="works-list">
      <h1 className="header">
        Our <span className="yellow">Excellent Works</span>
      </h1>

      {props.projects.map((data) => (
        <div className="list">
          <img
            className="project-thumbnail"
            src={props.baseUrl + "/" + data.work_picture}
            alt="FAVE Solution"
          />
          <div className="text-container">
            <h2 className="project-name">{data.work_name}</h2>
            <div className="project-desc">{data.work_details}</div>
            {/* <Link className="button" to={'/project/'+data.work_name} > */}
            <a
              href={data.work_process}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button text="Discover Our Work" />
            </a>
            {/* </Link> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WorksList;
