import React from "react";

import background from "../../../assets/images/home-page/hero-background.png";
import triangle from "../../../assets/images/home-page/hero-triangle.svg";

const Hero = () => {
  function scrollDown() {
    console.log("scroll");
    var windowCoords = document.documentElement.clientHeight;
    window.scrollTo({
      top: windowCoords,
      behavior: "smooth",
    });
  }

  return (
    <div
      className="hero"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="text-wrapper">
        <h1 className="title">
          <span class="text-white">Redefining </span>
          <span class="text-yellow">
            Your <br />
            Business{" "}
          </span>
          <span class="text-white">Experience </span>
        </h1>
        <div className="slogan">
          Creating top-notch websites, mobile apps and brand identity with you.
        </div>
      </div>

      <div className="see-more" onClick={() => scrollDown()}>
        <div>See More</div>
        <img src={triangle} alt="FAVE Solution" />
      </div>
    </div>
  );
};

export default Hero;
