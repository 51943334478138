import React from 'react';

const CardOurTeam = (props) => {
    return(
        <div className="card-wrapper">
            <img src={props.imageSrc} alt={props.imageAlt} />
            <div className="hl"></div>
            <div className="desc">
                <div className="name-title">{props.name}</div>
                <div className="position">{props.position}</div>
            </div>
        </div>
    )
}

export default CardOurTeam