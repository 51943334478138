import React from 'react';

import Navbar from '../components/common/Navbar';
import WorksList from '../components/pages/works-page/WorksList';
import LetsTalk from '../components/pages/home-page/lets-talk/LetsTalk';
import Footer from '../components/common/Footer';

const WorksPage = (props) => {
    return(
        <section className="workspage">
            <Navbar path={props.path} />
            <WorksList 
                projects={props.projects} 
                baseUrl={props.baseUrl}/>
            <LetsTalk />
            <Footer />
        </section>
    )
}

export default WorksPage