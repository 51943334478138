import React, { useState } from 'react';

import CardAboutSection from './CardAboutSection';

import {ReactComponent as Icon1} from '../../../../assets/images/about-page/about-us-icon1.svg';
import {ReactComponent as Icon2} from '../../../../assets/images/about-page/about-us-icon2.svg';
import {ReactComponent as Icon3} from '../../../../assets/images/about-page/about-us-icon3.svg';
import {ReactComponent as TriangleBG} from '../../../../assets/images/common/TrianglePolygonBG.svg';

const AboutSection = () => {
    const [active, setActive] = useState(1);

    const goTo = x => {setActive(x)}

    return(
        <section className="about-section">
            <div className="container">
                <div className="header-container">
                    <div className="vl"></div>
                    <h1 className="header">About <span>Us</span></h1>
                </div>
                <TriangleBG className="top-triangle triangle"/>
                <TriangleBG className="mid-triangle triangle"/>
                <div className="about-content">
                    {active === 1 && <CardAboutSection 
                        status="visibility-hidden"
                        yellow="Professional "
                        title2="Software House"
                        content="Meeting our customer’s requirement and delivering products on time are some of our ways of being professional."
                    />}
                    {active === 2 && <CardAboutSection 
                        status="visibility-hidden"
                        title ="Our #1 Priority: "
                        yellow="YOU"
                        content="We always do our best in delivering the most satisfying product for our beloved customers."
                    />}
                    {active === 3 && <CardAboutSection
                        status="visibility-hidden"
                        title="We Ride On The "
                        yellow="Technology Wave"
                        content="Rapid technology development provides us a wide array of IT solution; we choose one best suited for each and every project we handle."
                    />}
                    <CardAboutSection 
                        status={active === 1 ? 'active' : ''}
                        yellow="Professional "
                        title2="Software House"
                        content="Meeting our customer’s requirement and delivering products on time are some of our ways of being professional."
                    />
                    <CardAboutSection
                        status={active === 2 ? 'active' : ''} 
                        title ="Our #1 Priority: "
                        yellow="YOU"
                        content="We always do our best in delivering the most satisfying product for our beloved customers."
                    />
                    <CardAboutSection
                        status={active === 3 ? 'active' : ''} 
                        title="We Ride On The "
                        yellow="Technology Wave"
                        content="Rapid technology development provides us a wide array of IT solution; we choose one best suited for each and every project we handle."
                    />
                    <div className="about-buttons-wrapper">
                        <div className={(active === 1 ? 'active' : '') + " button-wrapper"} onClick={() => goTo(1)}>
                            <Icon1/>
                        </div>
                        <div className={(active === 2 ? 'active' : '') + " button-wrapper"} onClick={() => goTo(2)}>
                            <Icon2/>
                        </div>
                        <div className={(active === 3 ? 'active' : '') + " button-wrapper"} onClick={() => goTo(3)}>
                            <Icon3/>
                        </div>
                    </div>
                </div>
                <TriangleBG className="bot-triangle triangle"/>
            </div>
        </section>
    )
}

export default AboutSection