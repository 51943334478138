import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../Button';

const AboutUs = () => {
    return(
        <section className="about-us">
             <div className="container">
                <div className="header">About <span>FAVE</span></div>
                <p>
                    <b><span>FAVE</span> Solution</b> is a professional software house focused on development and services. <b><span>FAVE</span> Solution</b> has spent many years to <b>provide IT Solution</b> to personal and companies in Indonesia. We create Websites and Mobile Apps with our utmost care for our clients. 
                 </p>
                 <Link to="/about-us">
                    <Button text="More About Us"/>
                </Link>  
            </div>
        </section>
    )
}

export default AboutUs;