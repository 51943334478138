import React from 'react';

import ArrowIcon2 from './ArrowIcon2';

const Button2 = props =>{
    return (
        <div className="button2-wrapper">
            <div className="button2-btn">
                <p>{props.text}</p>
            </div>
            <ArrowIcon2 />
        </div>
    )
}

export default Button2;